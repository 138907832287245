<template>
  <div class="page-wrapper">
    <header class="navbar">
      <svg class="symbol" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M89.4296 8.28277H83.3162L75.5576 22.5461L63.1049 0H56.9916L50.4998 11.9359L43.9081 0H37.7948L25.279 23.0015L17.2523 8.28277H11.139L0.300049 28.213H6.44492L14.2141 13.8839L21.9779 28.213H22.4458H28.1228H28.5854L40.8646 5.60112L47.451 17.537L41.6426 28.213H47.7874L50.5419 23.1381L53.3436 28.213H59.4884L53.5906 17.5269L60.0667 5.60112L72.5088 28.1523L72.5456 28.213H78.6222L78.6537 28.1523L86.386 13.8839L94.1552 28.213H100.3L89.4296 8.28277Z"
          fill="white"
        />
      </svg>
      <svg class="logo" width="250" height="47" viewBox="0 0 250 47" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          class="digital"
          d="M36.2133 41.298C36.2143 41.6574 36.144 42.0135 36.0065 42.3455C35.7319 42.9939 35.2254 43.5165 34.5859 43.8112C34.2608 43.9589 33.9089 44.0385 33.5518 44.0449H28.9527V46.0321H27.5185V38.5466H33.5204C33.882 38.5504 34.2389 38.6301 34.5679 38.7804C34.8874 38.9247 35.1771 39.1275 35.4221 39.3783C35.6677 39.6297 35.8631 39.9258 35.9975 40.2505C36.1417 40.5808 36.2152 40.9376 36.2133 41.298V41.298ZM33.363 42.7951C33.5637 42.7955 33.7624 42.7557 33.9475 42.6782C34.1264 42.6029 34.2895 42.4946 34.4285 42.359C34.5656 42.2213 34.674 42.0578 34.7477 41.878C34.8288 41.6923 34.8702 41.4917 34.8691 41.289C34.8705 41.0893 34.8291 40.8915 34.7477 40.7091C34.6739 40.5307 34.5654 40.3687 34.4285 40.2325C34.2908 40.0955 34.1273 39.987 33.9475 39.9133C33.763 39.8338 33.5639 39.7939 33.363 39.7965H28.9527V42.7951H33.363Z"
          fill="white"
        />
        <path
          class="digital"
          d="M45.5016 44.1304H40.8664L39.8774 46.0321H38.2409L42.1702 38.5466H44.2023L48.1046 46.0321H46.4816L45.5016 44.1304ZM43.1862 39.711L41.5183 42.8581H44.8407L43.1862 39.711Z"
          fill="white"
        />
        <path
          class="digital"
          d="M58.1256 44.0854L60.0453 46.0321H58.0492L56.0846 44.0584H53.2882V46.0456H51.8586V38.5466H57.8559C58.2176 38.5504 58.5744 38.6301 58.9034 38.7804C59.223 38.9247 59.5127 39.1275 59.7576 39.3783C60.0032 39.6307 60.1999 39.9264 60.3376 40.2505C60.4782 40.5818 60.5501 40.9382 60.5489 41.298C60.5471 41.873 60.3681 42.4334 60.0363 42.903C59.8703 43.1328 59.6702 43.336 59.4429 43.5055C59.2118 43.6798 58.9536 43.815 58.6786 43.9056L58.1256 44.0854ZM57.6985 42.7951C58.0991 42.7916 58.482 42.63 58.764 42.3455C58.9011 42.2068 59.0108 42.0437 59.0877 41.8645C59.1652 41.6778 59.205 41.4777 59.2046 41.2756C59.2052 41.0782 59.1656 40.8828 59.0883 40.7011C59.011 40.5195 58.8977 40.3555 58.755 40.2191C58.6165 40.083 58.4532 39.9746 58.274 39.8999C58.0913 39.8198 57.8935 39.78 57.694 39.783H53.2837V42.7816L57.6985 42.7951Z"
          fill="white"
        />
        <path class="digital" d="M72.9796 38.5466V39.7965H69.347V46.0321H67.9174V39.7965H64.2893V38.5466H72.9796Z" fill="white" />
        <path
          class="digital"
          d="M85.4194 39.7965H78.1452V41.6757H83.3243V42.9255H78.1452V44.7822H85.4194V46.0321H76.7156V38.5466H85.4194V39.7965Z"
          fill="white"
        />
        <path
          class="digital"
          d="M97.7737 46.0321H96.1193L90.576 40.4034V46.0321H89.1464V38.5466H90.8772L96.3441 44.1708V38.5466H97.7737V46.0321Z"
          fill="white"
        />
        <path
          class="digital"
          d="M108.797 44.1304H104.149L103.155 46.0321H101.523L105.452 38.5466H107.48L111.4 46.0321H109.777L108.797 44.1304ZM106.477 39.711L104.814 42.8581H108.136L106.477 39.711Z"
          fill="white"
        />
        <path class="digital" d="M114.664 38.5645H116.094V46.0319H114.664V38.5645Z" fill="white" />
        <path
          class="digital"
          d="M126.992 44.0854L128.911 46.0321H126.915L124.951 44.0584H122.154V46.0456H120.72V38.5466H126.722C127.084 38.5504 127.44 38.6301 127.769 38.7804C128.089 38.9247 128.379 39.1275 128.624 39.3783C128.869 39.6297 129.065 39.9258 129.199 40.2505C129.343 40.5808 129.417 40.9376 129.415 41.298C129.416 41.5867 129.37 41.8735 129.28 42.1477C129.193 42.417 129.065 42.6715 128.902 42.903C128.734 43.1307 128.534 43.3335 128.309 43.5055C128.077 43.679 127.819 43.8141 127.545 43.9056L126.992 44.0854ZM126.542 42.7951C126.743 42.795 126.941 42.7553 127.126 42.6782C127.305 42.6029 127.469 42.4946 127.608 42.359C127.745 42.2213 127.853 42.0578 127.927 41.878C128.006 41.692 128.046 41.4914 128.044 41.289C128.043 41.0919 128.003 40.8968 127.926 40.7154C127.848 40.534 127.736 40.3698 127.594 40.2325C127.455 40.0975 127.292 39.9893 127.113 39.9133C126.93 39.8337 126.732 39.7939 126.533 39.7965H122.123V42.7951H126.542Z"
          fill="white"
        />
        <path
          class="digital"
          d="M141.841 39.7965H134.58V41.6757H139.76V42.9255H134.58V44.7822H141.841V46.0321H133.151V38.5466H141.841V39.7965Z"
          fill="white"
        />
        <path
          class="digital"
          d="M161.748 42.2827C161.75 42.7705 161.654 43.2536 161.465 43.7033C161.283 44.1413 161.024 44.5433 160.701 44.8902C160.383 45.2316 160.003 45.5093 159.581 45.7084C159.161 45.9129 158.7 46.0234 158.233 46.0321H153.062V38.5557H158.403C158.851 38.5534 159.293 38.6566 159.694 38.8569C160.099 39.0533 160.463 39.3266 160.764 39.6617C161.076 40.009 161.322 40.4115 161.488 40.8485C161.665 41.3055 161.754 41.7924 161.748 42.2827ZM157.873 44.7823C158.199 44.7832 158.523 44.7158 158.822 44.5845C159.415 44.3216 159.883 43.8378 160.125 43.2358C160.253 42.928 160.319 42.598 160.319 42.2647C160.32 41.9453 160.257 41.629 160.134 41.3341C159.902 40.7556 159.463 40.2843 158.902 40.0123C158.622 39.8742 158.316 39.7961 158.003 39.783H154.47V44.7284H155.675H156.776L157.873 44.7823Z"
          fill="white"
        />
        <path class="digital" d="M165.893 38.5645H167.323V46.0319H165.893V38.5645Z" fill="white" />
        <path
          class="digital"
          d="M171.068 42.2826C171.069 41.8128 171.159 41.3475 171.333 40.9114C171.499 40.4843 171.741 40.0912 172.048 39.7515C172.338 39.4096 172.691 39.1259 173.087 38.9153C173.488 38.7049 173.929 38.5794 174.381 38.5466H179.637V39.7965H174.777C174.465 39.8091 174.159 39.8856 173.878 40.0212C173.603 40.1568 173.356 40.3429 173.15 40.5697C172.94 40.8014 172.773 41.0676 172.655 41.3565C172.535 41.6535 172.474 41.9711 172.475 42.2916C172.472 42.6222 172.538 42.9498 172.669 43.2537C172.792 43.5526 172.97 43.8256 173.194 44.0584C173.422 44.283 173.691 44.4616 173.986 44.5844C174.278 44.716 174.596 44.7835 174.916 44.7822H178.27V42.912H175.159V41.6622H179.677V46.0321H174.552C174.084 46.0258 173.623 45.9152 173.203 45.7084C172.784 45.5098 172.407 45.232 172.093 44.8901C171.771 44.5439 171.515 44.1417 171.338 43.7033C171.155 43.252 171.064 42.7693 171.068 42.2826V42.2826Z"
          fill="white"
        />
        <path class="digital" d="M183.818 38.5645H185.252V46.0319H183.818V38.5645Z" fill="white" />
        <path class="digital" d="M197.669 38.5466V39.7965H194.037V46.0321H192.607V39.7965H188.979V38.5466H197.669Z" fill="white" />
        <path
          class="digital"
          d="M207.281 44.1304H202.646L201.653 46.0321H200.021L203.95 38.5466H205.978L209.898 46.0321H208.275L207.281 44.1304ZM204.962 39.711L203.298 42.8581H206.62L204.962 39.711Z"
          fill="white"
        />
        <path class="digital" d="M213.634 38.5466H215.063V44.7822H221.578V46.0321H213.634V38.5466Z" fill="white" />
        <path
          class="pano"
          d="M6.2536 23.8859H0V0.0673828H18.4865C19.6762 0.083037 20.8499 0.343185 21.9348 0.831661C22.9803 1.30186 23.9275 1.96539 24.7267 2.78731C25.5265 3.61358 26.1653 4.58172 26.6104 5.64212C27.0763 6.73645 27.3134 7.9145 27.3072 9.10385C27.3149 10.314 27.0715 11.5127 26.5924 12.624C25.6777 14.7863 23.9596 16.5092 21.7999 17.43C20.69 17.9086 19.4929 18.152 18.2842 18.1448H6.2536V23.8859ZM6.2536 12.4037H18.5855C19.0248 12.4041 19.4594 12.3138 19.8622 12.1385C20.4632 11.885 20.9759 11.4596 21.3359 10.9157C21.6958 10.3718 21.887 9.73361 21.8853 9.08137C21.8845 8.65035 21.7974 8.22386 21.6291 7.82706C21.4644 7.433 21.2211 7.07659 20.9143 6.77955C20.6019 6.48315 20.2397 6.24421 19.8443 6.07371C19.4201 5.88503 18.9597 5.79143 18.4955 5.79947H6.2536V12.4037Z"
          fill="white"
        />
        <path
          class="pano"
          d="M34.424 18.3831L31.5332 23.8859H24.4344L36.9371 0.0673828H44.0584L56.5656 23.8949H49.4623L46.576 18.3921L34.424 18.3831ZM40.5067 6.74358L37.4137 12.642H43.6088L40.5067 6.74358Z"
          fill="white"
        />
        <path
          class="pano"
          d="M79.993 23.8859L65.3818 9.07238V23.9084H59.1282V0.0673828L65.6381 0.103349L80.0245 14.8809V0.0673828H86.2781V23.8949L79.993 23.8859Z"
          fill="white"
        />
        <path
          class="pano"
          d="M129.298 23.8859H123.044V0.0673828H141.531C142.721 0.083037 143.894 0.343185 144.979 0.831661C146.029 1.30039 146.981 1.96399 147.785 2.78731C148.584 3.61358 149.223 4.58172 149.668 5.64212C150.134 6.73645 150.371 7.9145 150.365 9.10385C150.373 10.0693 150.221 11.0294 149.916 11.9452C149.32 13.7189 148.193 15.2664 146.688 16.378C145.942 16.9387 145.11 17.3745 144.224 17.6683L150.374 23.8859H141.616L135.925 18.1448H129.298V23.8859ZM129.298 12.4037H141.616C142.054 12.4066 142.487 12.3194 142.889 12.1475C143.49 11.894 144.002 11.4686 144.362 10.9247C144.722 10.3808 144.913 9.7426 144.912 9.09036C144.911 8.65934 144.824 8.23285 144.655 7.83605C144.491 7.44199 144.248 7.08558 143.941 6.78854C143.628 6.49214 143.266 6.2532 142.871 6.0827C142.455 5.89676 142.004 5.8032 141.549 5.80846H129.298V12.4037Z"
          fill="white"
        />
        <path
          class="pano"
          d="M160.867 18.3833L157.976 23.8861H150.878L163.38 0.0585938H170.515L183.022 23.8861H175.919L173.033 18.3833H160.867ZM166.95 6.74378L163.857 12.6242H170.043L166.95 6.74378Z"
          fill="white"
        />
        <path
          class="pano"
          d="M191.82 8.56436V23.8859H185.571V0.0673828H191.82L200.421 8.05185L209.017 0.0673828H215.27V23.8949H209.017L208.99 8.57335L200.407 16.5803L191.82 8.56436Z"
          fill="white"
        />
        <path
          class="pano"
          d="M227.84 18.3833L224.954 23.8861H217.851L230.358 0.0585938H237.493L250 23.8861H242.897L240.006 18.3833H227.84ZM233.923 6.74378L230.835 12.6242H237.016L233.923 6.74378Z"
          fill="white"
        />
        <path
          class="pano"
          d="M110.596 18.3337H99.0909C96.6597 18.3337 94.3281 17.3679 92.609 15.6488C90.8899 13.9297 89.9241 11.598 89.9241 9.16685C89.9241 6.73565 90.8899 4.40402 92.609 2.68491C94.3281 0.96579 96.6597 0 99.0909 0L110.596 0C113.027 0 115.358 0.96579 117.078 2.68491C118.797 4.40402 119.762 6.73565 119.762 9.16685C119.762 11.598 118.797 13.9297 117.078 15.6488C115.358 17.3679 113.027 18.3337 110.596 18.3337V18.3337ZM99.0909 5.84448C98.6546 5.84448 98.2226 5.93042 97.8195 6.09738C97.4164 6.26435 97.0502 6.50907 96.7417 6.81758C96.4332 7.12609 96.1884 7.49234 96.0215 7.89543C95.8545 8.29852 95.7686 8.73055 95.7686 9.16685C95.7686 9.60314 95.8545 10.0352 96.0215 10.4383C96.1884 10.8413 96.4332 11.2076 96.7417 11.5161C97.0502 11.8246 97.4164 12.0693 97.8195 12.2363C98.2226 12.4033 98.6546 12.4892 99.0909 12.4892H110.596C111.477 12.4892 112.322 12.1392 112.945 11.5161C113.568 10.893 113.918 10.048 113.918 9.16685C113.918 8.2857 113.568 7.44064 112.945 6.81758C112.322 6.19452 111.477 5.84448 110.596 5.84448H99.0909Z"
          fill="white"
        />
        <path class="pano" d="M117.613 20.9771H92.2079V23.8408H117.613V20.9771Z" fill="white" />
      </svg>
      <a href="#" class="cta" @click.prevent="showsidepanel">Contactez-nous</a>
    </header>

    <section class="hero" :class="{ caro: heroPos === 1, soso: heroPos === 2 }">
      <div class="intro">
        <h1 class="title" data-inview="fadeInUp" data-delay="100">On vous emmène ailleurs</h1>
        <p class="sub-title" data-inview="fadeInUp" data-delay="200">Votre département marketing numérique</p>
        <div class="partners" data-inview="fadeInUp" data-delay="300">
          <div class="partner" @click.prevent="scrollHero(1)">
            <div><img src="@/assets/img/arrow-left.svg" class="arrow left" alt="Arrow left" /></div>
            <div><img src="@/assets/img/caro.png" class="patner-img" alt="Carolanne Lavoie-Pilon" /></div>
          </div>
          <div class="partner" @click.prevent="scrollHero(2)">
            <div><img src="@/assets/img/soso.png" class="patner-img" alt="Sophie Naert" /></div>
            <div><img src="@/assets/img/arrow-right.svg" class="arrow right" alt="Arrow right" /></div>
          </div>
        </div>
      </div>

      <a v-scroll-to:-100 href="#goals" class="scroll-down">
        <p>Comment pouvons-nous vous aider ?</p>
        <img src="@/assets/img/arrow-down.svg" alt="Arrow down" />
      </a>

      <div class="soso-wrap">
        <img src="@/assets/img/sophie-naert.png" class="profil-img" alt="Sophie Naert" />
        <div class="data">
          <h2 class="name">Sophie Naert</h2>
          <p class="poste">Experte numérique et fondatrice</p>
          <img src="@/assets/img/quote.svg" class="quote" alt="Quote" />
          <p class="quote-txt">
            Mon quotidien, faire rayonner les marques sur Internet. Ma passion, la stratégie, les chiffres et les performances. Quand est-ce qu'on se parle
            ?
          </p>
          <a href="#" class="cta" @click.prevent="showsidepanel">Planifions un appel</a>
        </div>
      </div>

      <div class="caro-wrap">
        <img src="@/assets/img/carolanne-lavoie-pilon.png" class="profil-img" alt="Carolanne Lavoie-Pilon" />
        <div class="data">
          <h2 class="name">Carolanne<br />Lavoie-Pilon</h2>
          <p class="poste">Experte numérique et fondatrice</p>
          <img src="@/assets/img/quote.svg" class="quote" alt="Quote" />
          <p class="quote-txt">
            Passionnée du numérique, j’apporte les performances des PME à un autre niveau avec des stratégies innovantes et efficaces. Je suis l'alliée
            qu'il vous faut!
          </p>
          <a href="#" class="cta" @click.prevent="showsidepanel">Planifions un appel</a>
        </div>
      </div>

      <div class="back-soso" @click.prevent="scrollHero(0)"><img src="@/assets/img/back-left.svg" alt="Arrow left" /></div>
      <div class="back-caro" @click.prevent="scrollHero(0)"><img src="@/assets/img/back-right.svg" alt="Arrow left" /></div>
      
      <div class="social-links">
        <a href="https://www.facebook.com/panoramadigitalpartner" target="_blank">
          <svg class="social-icon" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M20 0C8.95431 0 0 8.95431 0 20C0 31.0457 8.95431 40 20 40C31.0457 40 40 31.0457 40 20C40 8.95431 31.0457 0 20 0ZM22.0846 20.8785V31.7596H17.5826V20.8789H15.3333V17.1293H17.5826V14.878C17.5826 11.819 18.8526 10 22.461 10H25.4651V13.7501H23.5873C22.1826 13.7501 22.0897 14.2741 22.0897 15.2521L22.0846 17.1288H25.4863L25.0883 20.8785H22.0846Z" fill="white"/>
          </svg>
        </a>
        <a href="https://www.instagram.com/panorama_digital_partner" target="_blank">
          <svg class="social-icon" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M20 0C8.95431 0 0 8.95431 0 20C0 31.0457 8.95431 40 20 40C31.0457 40 40 31.0457 40 20C40 8.95431 31.0457 0 20 0ZM15.6027 9.39778C16.7405 9.346 17.1041 9.33333 20.001 9.33333H19.9977C22.8955 9.33333 23.2577 9.346 24.3955 9.39778C25.5311 9.44978 26.3066 9.62956 26.9866 9.89334C27.6889 10.1656 28.2822 10.53 28.8755 11.1234C29.4689 11.7162 29.8333 12.3114 30.1067 13.0129C30.3689 13.6912 30.5489 14.4663 30.6022 15.6018C30.6533 16.7396 30.6667 17.1032 30.6667 20.0001C30.6667 22.897 30.6533 23.2597 30.6022 24.3975C30.5489 25.5326 30.3689 26.308 30.1067 26.9864C29.8333 27.6877 29.4689 28.2829 28.8755 28.8758C28.2829 29.4691 27.6886 29.8344 26.9873 30.1069C26.3086 30.3707 25.5326 30.5504 24.397 30.6024C23.2593 30.6542 22.8968 30.6669 19.9997 30.6669C17.103 30.6669 16.7396 30.6542 15.6018 30.6024C14.4665 30.5504 13.6912 30.3707 13.0125 30.1069C12.3114 29.8344 11.7162 29.4691 11.1236 28.8758C10.5305 28.2829 10.166 27.6877 9.89334 26.9862C9.62978 26.308 9.45 25.5328 9.39778 24.3973C9.34622 23.2595 9.33333 22.897 9.33333 20.0001C9.33333 17.1032 9.34667 16.7394 9.39756 15.6016C9.44867 14.4665 9.62867 13.6912 9.89312 13.0127C10.1665 12.3114 10.5309 11.7162 11.1242 11.1234C11.7171 10.5302 12.3123 10.1658 13.0138 9.89334C13.692 9.62956 14.4672 9.44978 15.6027 9.39778Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M19.0441 11.2556C19.2299 11.2553 19.4298 11.2554 19.6455 11.2555L20.001 11.2556C22.849 11.2556 23.1866 11.2658 24.3113 11.3169C25.3513 11.3645 25.9157 11.5383 26.2917 11.6843C26.7895 11.8776 27.1444 12.1087 27.5175 12.4821C27.8909 12.8554 28.122 13.211 28.3158 13.7087C28.4618 14.0843 28.6358 14.6487 28.6831 15.6888C28.7342 16.8132 28.7453 17.151 28.7453 19.9977C28.7453 22.8444 28.7342 23.1822 28.6831 24.3066C28.6355 25.3466 28.4618 25.9111 28.3158 26.2866C28.1224 26.7844 27.8909 27.1389 27.5175 27.512C27.1442 27.8853 26.7897 28.1164 26.2917 28.3098C25.9162 28.4564 25.3513 28.6298 24.3113 28.6773C23.1868 28.7284 22.849 28.7396 20.001 28.7396C17.1528 28.7396 16.8152 28.7284 15.6907 28.6773C14.6507 28.6293 14.0863 28.4556 13.7101 28.3096C13.2123 28.1162 12.8567 27.8851 12.4834 27.5118C12.11 27.1384 11.8789 26.7838 11.6851 26.2858C11.5391 25.9102 11.3651 25.3457 11.3178 24.3057C11.2667 23.1813 11.2565 22.8435 11.2565 19.995C11.2565 17.1465 11.2667 16.8105 11.3178 15.6861C11.3654 14.6461 11.5391 14.0816 11.6851 13.7056C11.8785 13.2078 12.11 12.8523 12.4834 12.4789C12.8567 12.1056 13.2123 11.8745 13.7101 11.6807C14.0861 11.534 14.6507 11.3607 15.6907 11.3129C16.6747 11.2685 17.0561 11.2552 19.0441 11.2529V11.2556ZM25.6949 13.0265C24.9882 13.0265 24.4149 13.5992 24.4149 14.3061C24.4149 15.0127 24.9882 15.5861 25.6949 15.5861C26.4016 15.5861 26.9749 15.0127 26.9749 14.3061C26.9749 13.5994 26.4016 13.026 25.6949 13.026V13.0265ZM14.5232 20C14.5232 16.9749 16.9757 14.5223 20.0008 14.5221C23.0259 14.5221 25.4779 16.9748 25.4779 20C25.4779 23.0251 23.0261 25.4767 20.001 25.4767C16.9758 25.4767 14.5232 23.0251 14.5232 20Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M20.001 16.4446C21.9646 16.4446 23.5566 18.0364 23.5566 20.0002C23.5566 21.9637 21.9646 23.5558 20.001 23.5558C18.0372 23.5558 16.4454 21.9637 16.4454 20.0002C16.4454 18.0364 18.0372 16.4446 20.001 16.4446V16.4446Z" fill="white"/>
          </svg>
        </a>
        <a href="https://www.linkedin.com/company/panoramadigitalpartner/" target="_blank">
          <svg class="social-icon" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M20 0C8.95431 0 0 8.95431 0 20C0 31.0457 8.95431 40 20 40C31.0457 40 40 31.0457 40 20C40 8.95431 31.0457 0 20 0ZM9.60134 16.5648H14.1338V30.1829H9.60134V16.5648ZM14.4323 12.3522C14.4029 11.017 13.448 10 11.8975 10C10.347 10 9.33333 11.017 9.33333 12.3522C9.33333 13.6598 10.317 14.7061 11.8387 14.7061H11.8676C13.448 14.7061 14.4323 13.6598 14.4323 12.3522ZM25.2609 16.2451C28.2435 16.2451 30.4795 18.1919 30.4795 22.3748L30.4793 30.183H25.947V22.8973C25.947 21.0673 25.2912 19.8185 23.6504 19.8185C22.3982 19.8185 21.6524 20.6604 21.3248 21.4735C21.205 21.7649 21.1756 22.1709 21.1756 22.5779V30.1834H16.6426C16.6426 30.1834 16.7023 17.8431 16.6426 16.5653H21.1756V18.4941C21.7771 17.5669 22.8545 16.2451 25.2609 16.2451Z" fill="white"/>
          </svg>
        </a>
      </div>

    </section>

    <section id="goals" class="goals-wrap">
      <div class="title-wrap">
        <h2 class="title">Que<br />souhaitez-vous ?</h2>
        <p class="regular-text">Atteignons ensemble vos objectifs d'affaires en alliant passion, analyse et performance.</p>
        <a href="#" class="cta" @click.prevent="showsidepanel">Contactez-nous</a>
      </div>
      <div class="goals">
        <div class="goal empty"></div>

        <div class="goal empty"></div>

        <div class="goal" data-inview="fadeInUp" data-delay="100">
          <img src="@/assets/img/icon-goal-1.svg" alt="Panorama Digital" />
          <p class="goal-title">Faire découvrir ma marque, mes produits/services</p>
        </div>

        <div class="goal light" data-inview="fadeInUp" data-delay="200">
          <img src="@/assets/img/icon-goal-2.svg" alt="Panorama Digital" />
          <p class="goal-title">Augmenter mes followers sur les réseaux sociaux</p>
        </div>

        <div class="goal empty"></div>

        <div class="goal" data-inview="fadeInUp" data-delay="300">
          <img src="@/assets/img/icon-goal-3.svg" alt="Panorama Digital" />
          <p class="goal-title">Recruter de nouveaux employés</p>
        </div>

        <div class="goal light" data-inview="fadeInUp" data-delay="400">
          <img src="@/assets/img/icon-goal-4.svg" alt="Panorama Digital" />
          <p class="goal-title">Augmenter mon chiffre d'affaire</p>
        </div>

        <div class="goal" data-inview="fadeInUp" data-delay="500">
          <img src="@/assets/img/icon-goal-5.svg" alt="Panorama Digital" />
          <p class="goal-title">Automatiser des infolettres percutantes</p>
        </div>
      </div>
    </section>

    <section class="expertises-wrap">
      <div class="title-wrap">
        <h2 class="title">Nos expertises</h2>
        <p class="regular-text">
          À la pointe des innovations, passionnées par notre domaine et stratèges dans l'âme, nous collaborons avec vous, comme si nous étions votre propre
          département web.
        </p>
        <div class="badges">
          <!-- <img src="@/assets/img/google-partner.png" alt="Google partner" /> -->
          <a href="https://www.google.com/partners/agency?id=5504973901" target="_blank">
            <img src="https://www.gstatic.com/partners/badge/images/2023/PartnerBadgeClickable.svg" />
          </a>
          <img src="@/assets/img/facebook-partner.png" alt="Facebook partner" />
          <img src="@/assets/img/Partner-Badges-2023_Partner.png" alt="Microsoft Partner" />
        </div>
      </div>

      <div class="expertises">
        <div class="expertise-title" :class="{ active: expertise === 1 }" @click.prevent="showExpertise(1)">
          <div class="line" data-inview="fadeInUp" data-delay="100">
            <h3 class="main-title small">Stratégie, conseils et formation</h3>
            <div class="plus-icon"></div>
          </div>
        </div>
        <div class="expertise-details" :class="{ active: expertise === 1 }">
          <p class="regular-text">
            Notre solide expérience et expertise nous permettent de vous guider à travers cet environnement en constante évolution. À l'affût des
            meilleures stratégies, nous connaissons les recettes qui marchent!<br />Nous offrons également des formations sur mesure en fonction de vos
            besoins!
          </p>
        </div>

        <div class="expertise-title" :class="{ active: expertise === 2 }" @click.prevent="showExpertise(2)">
          <div class="line" data-inview="fadeInUp" data-delay="200">
            <h3 class="main-title small">Référencement payant (SEM)</h3>
            <div class="plus-icon"></div>
          </div>
        </div>
        <div class="expertise-details" :class="{ active: expertise === 2 }">
          <p class="regular-text">
            Apparaissez dans les premiers résultats sur les moteurs de recherche dès qu'une personne est à la recherche de ce que vous proposez, grâce aux
            enchères sur des mots-clés pertinents
          </p>
        </div>

        <div class="expertise-title" :class="{ active: expertise === 3 }" @click.prevent="showExpertise(3)">
          <div class="line" data-inview="fadeInUp" data-delay="300">
            <h3 class="main-title small">Référencement organique (SEO)</h3>
            <div class="plus-icon"></div>
          </div>
        </div>
        <div class="expertise-details" :class="{ active: expertise === 3 }">
          <p class="regular-text">Gagnez en positionnement sur Google pour être présents sur la 1ère page de recherche.</p>
        </div>

        <div class="expertise-title" :class="{ active: expertise === 4 }" @click.prevent="showExpertise(4)">
          <div class="line" data-inview="fadeInUp" data-delay="400">
            <h3 class="main-title small">Réseaux Sociaux</h3>
            <div class="plus-icon"></div>
          </div>
        </div>
        <div class="expertise-details" :class="{ active: expertise === 4 }">
          <p class="regular-text">
            Facebook, Instagram, LinkedIn, YouTube, TikTok, SnapChat, Twich, Pinterest, n'ont plus de secrets pour nous. Renforcez votre présence et gagnez
            en notoriété auprès du bon public!
          </p>
        </div>

        <div class="expertise-title" :class="{ active: expertise === 5 }" @click.prevent="showExpertise(5)">
          <div class="line" data-inview="fadeInUp" data-delay="500">
            <h3 class="main-title small">Programmatique</h3>
            <div class="plus-icon"></div>
          </div>
        </div>
        <div class="expertise-details" :class="{ active: expertise === 5 }">
          <p class="regular-text">
            Faites découvrir votre marque auprès d'une clientèle extrêmement précises. Clients de votre compétition, visiteurs de magasins physiques,
            passionnés de votre domaine, et bien d'autres possibilités.
          </p>
        </div>

        <div class="expertise-title" :class="{ active: expertise === 6 }" @click.prevent="showExpertise(6)">
          <div class="line" data-inview="fadeInUp" data-delay="600">
            <h3 class="main-title small">Reciblage</h3>
            <div class="plus-icon"></div>
          </div>
        </div>
        <div class="expertise-details" :class="{ active: expertise === 6 }">
          <p class="regular-text">Reciblez vos clients, visiteurs du site, base de données avec des stratégies efficaces pour les faire convertir.</p>
        </div>

        <div class="expertise-title" :class="{ active: expertise === 7 }" @click.prevent="showExpertise(7)">
          <div class="line" data-inview="fadeInUp" data-delay="600">
            <h3 class="main-title small">Automatisation</h3>
            <div class="plus-icon"></div>
          </div>
        </div>
        <div class="expertise-details" :class="{ active: expertise === 7 }">
          <p class="regular-text">Maximisez votre parcours-client avec une stratégie d'infolettres percutantes et personnalisées.</p>
        </div>

        <div class="expertise-title" :class="{ active: expertise === 8 }" @click.prevent="showExpertise(8)">
          <div class="line" data-inview="fadeInUp" data-delay="600">
            <h3 class="main-title small">Rapports et analyse</h3>
            <div class="plus-icon"></div>
          </div>
        </div>
        <div class="expertise-details" :class="{ active: expertise === 8 }">
          <p class="regular-text">
            Le data est la clé de toutes bonnes analyses et stratégies. Capitalisons sur nos forces et analysons ensemble vos données récoltées!
          </p>
        </div>
      </div>
    </section>

    <section class="clients-wrap">
      <h2 class="title">Ils nous font confiance</h2>
      <div class="logos">
        <div class="logo" data-inview="fadeInUp" data-delay="100">
          <a href="https://ark8.net/" target="_blank"><img src="@/assets/img/clients/akr8.jpg" alt="Ark 8"/></a>
        </div>
        <div class="logo" data-inview="fadeInUp" data-delay="200">
          <a href="https://levelupwear.com/" target="_blank"><img src="@/assets/img/clients/levelup.jpg" alt="Level Up"/></a>
        </div>
        <div class="logo" data-inview="fadeInUp" data-delay="300">
          <a href="https://amjcampbell.com/" target="_blank"><img src="@/assets/img/clients/amj.jpg" alt="AMJ"/></a>
        </div>
        <div class="logo" data-inview="fadeInUp" data-delay="400">
          <a href="https://montreal.thefurnitureshop.ca/?lang=fr" target="_blank"><img src="@/assets/img/clients/fj.jpg" alt="FJ"/></a>
        </div>
        <div class="logo" data-inview="fadeInUp" data-delay="500">
          <a href="https://apexways.com/" target="_blank"><img src="@/assets/img/clients/apex.jpg" alt="Apex"/></a>
        </div>
        <div class="logo" data-inview="fadeInUp" data-delay="600">
          <a href="https://sainthenri.ca/en" target="_blank"><img src="@/assets/img/clients/saint-henri.jpg" alt="Saint-Henri"/></a>
        </div>
        <div class="logo" data-inview="fadeInUp" data-delay="700">
          <a href="https://helicraft.ca/" target="_blank"><img src="@/assets/img/clients/helicraft.jpg" alt="Helicraft"/></a>
        </div>
        <div class="logo" data-inview="fadeInUp" data-delay="800">
          <a href="https://maisonlepervier.com/" target="_blank"><img src="@/assets/img/clients/maison-epervier.jpg" alt="Maison l'épervier"/></a>
        </div>
      </div>
    </section>

    <section class="approche-wrap">
      <h2 class="title">Notre approche</h2>
      <div class="steps">
        <div class="step" data-inview="fadeInUp" data-delay="100">
          <div class="number"><p>1</p></div>
          <div class="wrap">
            <p class="step-title">Planification stratégique</p>
            <p class="regular-text">
              Alignement et compréhension de vos objectifs d’affaires. De l'analyse de votre écosystème à l'estimation des retombées, on planifie et
              détermine la meilleure stratégie à mettre en place.
            </p>
          </div>
        </div>
        <div class="step" data-inview="fadeInUp" data-delay="200">
          <div class="number"><p>2</p></div>
          <div class="wrap">
            <p class="step-title">Mise en place</p>
            <p class="regular-text">
              Intégration du suivi et de la mesure pour nous permettre de récolter des précieuses données et prendre des décisions éclairées. C'est le
              moment où l'on choisit et précise le ciblage, on paramètre les comptes et campagnes pour faire partir la machine.
            </p>
          </div>
        </div>
        <div class="step infinity" data-inview="fadeInUp" data-delay="300">
          <img src="@/assets/img/loop.svg" class="loop" alt="Loop" />
          <img src="@/assets/img/v-loop.svg" class="v-loop" alt="Loop" />
          <div class="number"><p>3</p></div>
          <div class="wrap">
            <p class="step-title">Gestion et Optimisation</p>
            <p class="regular-text">
              On optimise en continu, on fait des tests A/B pour pousser au maximum les résultats, on attribue le budget aux meilleures places. C'est le
              moment où l'on travaille pour avoir les meilleures performances et surtout rester efficace.
            </p>
          </div>
        </div>
        <div class="step" data-inview="fadeInUp" data-delay="400">
          <div class="number"><p>4</p></div>
          <div class="wrap">
            <p class="step-title">Rapport et exploration</p>
            <p class="regular-text">
              Grâce à un tableau de bord automatique et en temps réel, vous pourrez suivre aussi bien que nous les résultats des campagnes. À chaque mois,
              on s'assied ensemble, on émet des recommandations et on continue à innover.
            </p>
          </div>
        </div>
      </div>
    </section>

    <div class="team-photo"></div>

    <section class="team-wrap">
      <div class="title-wrap">
        <h2 class="title">Notre équipe</h2>
        <p class="regular-text">
          Avec un portfolio de marques montréalaises, québécoises, américaines et européennes, nous nous démarquons des agences traditionnelles et agissons
          beaucoup plus en tant que partenaire d’affaires avec nos clients. Nos grandes ambitions nous portent à collaborer avec chaque client comme si
          nous étions leur propre département web.
        </p>
      </div>
      <div class="player" data-inview="fadeInUp" data-delay="100">
        <img src="@/assets/img/caro-2.jpg" alt="Carolanne Lavoie-Pilon" />
        <div class="data">
          <h3 class="name">Carolanne Lavoie-Pilon</h3>
          <p class="regular-text">Experte numérique et fondatrice</p>
          <a href="mailto:carolanne@panoramadigital.ca">carolanne@panoramadigital.ca</a>
          <a href="tel:5148335291">514 833-5291</a>
          <a href="#" class="cta" @click.prevent="showsidepanel">Planifions un appel</a>
        </div>
      </div>
      <div class="player" data-inview="fadeInUp" data-delay="200">
        <img src="@/assets/img/soso-2.jpg" alt="Sophie Naert" />
        <div class="data">
          <h3 class="name">Sophie Naert</h3>
          <p class="regular-text">Experte numérique et fondatrice</p>
          <a href="mailto:sophie@panoramadigital.ca">sophie@panoramadigital.ca</a>
          <a href="tel:5146602232">514 660-2232</a>
          <a href="#" class="cta" @click.prevent="showsidepanel">Planifions un appel</a>
        </div>
      </div>
      <div class="player" data-inview="fadeInUp" data-delay="300">
        <img src="@/assets/img/nico.jpg" alt="Nicolas Duval" />
        <div class="data">
          <h3 class="name">Nicolas Duval</h3>
          <p class="regular-text">Gestionnaire de campagnes</p>
          <a href="mailto:nicolas@panoramadigital.ca">nicolas@panoramadigital.ca</a>
        </div>
      </div>
      <div class="player" data-inview="fadeInUp" data-delay="200">
        <img src="@/assets/img/photo-a-venir.jpg" alt="Marc-André Boucher" />
        <div class="data">
          <h3 class="name">Marc-André Boucher</h3>
          <p class="regular-text">Coordonnateur de campagnes</p>
          <a href="mailto:marcandre@panoramadigital.ca">marcandre@panoramadigital.ca</a>
        </div>
      </div>
      <div class="player" data-inview="fadeInUp" data-delay="200">
        <img src="@/assets/img/photo-a-venir.jpg" alt="Lisa Molières" />
        <div class="data">
          <h3 class="name">Lisa Molières</h3>
          <p class="regular-text">Coordonnatrice de campagnes</p>
          <a href="mailto:lisa@panoramadigital.ca">lisa@panoramadigital.ca</a>
        </div>
      </div>
      <div class="player" data-inview="fadeInUp" data-delay="200">
        <img src="@/assets/img/photo-a-venir.jpg" alt="Julie Dugas" />
        <div class="data">
          <h3 class="name">Julie Dugas</h3>
          <p class="regular-text">Chargée de projets</p>
          <a href="mailto:julie@panoramadigital.ca">julie@panoramadigital.ca</a>
        </div>
      </div>
    </section>

    <section class="footer-cta">
      <h2 class="title" data-inview="fadeInUp" data-delay="100">Et si on devenait partenaires<br />de vos performances numériques ?</h2>
      <a href="#" class="cta" data-inview="fadeInUp" data-delay="200" @click.prevent="showsidepanel">Contactez-nous</a>
    </section>

    <footer class="main-footer">
      <p class="regular-text">© Tous droits réservés - Panorama Digital</p>
      <a href="https://jnv.dev/" target="_blank" class="credits">
        <p class="regular-text">Avec passion par</p>
        <img src="@/assets/img/jnv.svg" alt="J&V logo" />
      </a>
    </footer>

    <div class="overlay-side-panel" :class="{ show: sidepanel }">
      <img v-if="!formSuccess" src="@/assets/img/close.svg" alt="close" class="close" @click.prevent="hidesidepanel" />
      <div class="side-panel">
        <h3 class="title">Discutons de vos<br />objectifs d’affaires</h3>
        <p v-if="formSuccess" class="regular-text">
          Merci! Nous avons bien reçu votre demande. Nous vous répondrons dans les plus brefs délais.<br /><br />
        </p>
        <a v-if="formSuccess" href="/" class="cta">Retour</a>
        <form v-if="!formSuccess" action="/success" name="contact" method="post" data-netlify="true" data-netlify-honeypot="b-address-ot-field">
          <input type="hidden" name="form-name" value="contact" />

          <p class="b-address-ot-field">
            <label>
              Vous devez ignorer ce champs pour protection du formualre. Simplement laisser vide. Merci : <input name="b-address-ot-field" tabindex="-1" />
            </label>
          </p>

          <input type="text" placeholder="Prénom et nom" name="nom" required />
          <input type="mail" placeholder="Courriel" name="email" required />
          <input type="text" placeholder="Téléphone" name="telephone" />

          <select name="objectif">
            <option value="">Quel est votre objectif ?</option>
            <option value="Générer des ventes en ligne">Générer des ventes en ligne</option>
            <option value="Faire rayonner votre entreprise">Faire rayonner votre entreprise</option>
            <option value="Développez vos infolettres">Automatisez votre parcours-client</option>
            <option value="Augmentez vos abonnés sur IG">Augmentez vos abonnés sur IG</option>
            <option value="Générer du trafic sur votre site Web">Générer du trafic sur votre site Web</option>
          </select>

          <textarea placeholder="Message" name="message"></textarea>

          <input type="submit" value="Envoyer" />
        </form>
      </div>
      <div class="opacity" @click.prevent="hidesidepanel"></div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

// Intro Animation Scripts
require('@/misc/animations-layer')

export default {
  data() {
    return {
      expertise: 0,
      heroPos: 0,
      sidepanel: false,
      formSuccess: false
    }
  },

  head() {
    return {
      title: {
        inner: 'Votre département marketing numérique'
      },
      meta: [
        {
          name: 'description',
          content: "Atteignons ensemble vos objectifs d'affaires en alliant passion, analyse et performance. On vous emmène à un autre niveau.",
          id: 'desc'
        },
        { name: 'application-name', content: 'Panorama Digital' },
        {
          name: 'twitter:title',
          content: "Atteignons ensemble vos objectifs d'affaires en alliant passion, analyse et performance. On vous emmène à un autre niveau."
        },
        { name: 'twitter:card', content: 'summary' },
        {
          n: 'twitter:description',
          c: "Atteignons ensemble vos objectifs d'affaires en alliant passion, analyse et performance. On vous emmène à un autre niveau."
        },
        // Facebook / Open Graph
        { property: 'og:title', content: 'Panorama Digital' },
        { property: 'og:site_name', content: 'Panorama Digital' },
        {
          property: 'og:description',
          content: "Atteignons ensemble vos objectifs d'affaires en alliant passion, analyse et performance. On vous emmène à un autre niveau."
        },
        // Og Image
        {
          p: 'og:image',
          c: 'https://panorama-digital.netlify.app/img/og.jpg'
        },
        {
          name: 'twitter:image',
          content: 'https://panorama-digital.netlify.app/img/og.jpg'
        }
      ]
    }
  },

  watch: {
    $route: {
      handler($route) {
        if ($route && $route.path === '/success') {
          this.sidepanel = true
          this.formSuccess = true
        }
      },
      immediate: true
    }
  },
  created() {
    // NavBar Follow
    this.toogleNavBarStatus()
    window.addEventListener('scroll', () => {
      this.toogleNavBarStatus()
    })
  },

  methods: {
    showExpertise(value) {
      if (this.expertise === value) {
        this.expertise = 0
      } else {
        this.expertise = value
      }
    },
    scrollHero(value) {
      if (this.heroPos === value) {
        this.heroPos = 0
      } else {
        this.heroPos = value
      }
    },
    showsidepanel() {
      if (this.sidepanel) {
        this.sidepanel = false
      } else {
        this.sidepanel = true
      }
    },
    hidesidepanel() {
      this.sidepanel = false
    },
    toogleNavBarStatus() {
      const st = document.documentElement.scrollTop

      if (!document.querySelector('.navbar')) {
        return
      }

      if (st >= 40) {
        document.querySelector('.navbar').classList.add('sticky')
      } else {
        document.querySelector('.navbar').classList.remove('sticky')
      }
    }
  },
  computed: mapState('app', ['appTitle'])
}
</script>

<style lang="scss" scoped>
@import '@/theme/animations-layer.scss';
@import '@/theme/variables.scss';
@import '@/theme/cta.scss';
@import '@/theme/typos.scss';
@import '@/theme/style.scss';
.b-address-ot-field {
  position: absolute;
  top: -9527px;
  left: -9256px;
  z-index: -1;
  width: 0;
  height: 0;
  overflow: hidden;
}
</style>
